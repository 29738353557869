var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var bX,gla,dX,fX,gX,hla,iX,jX,aX,kX,ila,jla,kla,lla,nX,oX,qX,rX,sX,tX,uX,vX,cX,nla,ola,yX,pla,zX,qla,BX,rla,sla,tla,ula,vla,YW;$CLJS.ZW=function(a,b){if("string"===typeof b)return YW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.$W=function(a,b,c){var d=$CLJS.fm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
bX=function(a){var b=aX;return $CLJS.KA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};gla=function(a){var b=cX;return $CLJS.KA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};dX=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Gi,a],null));};
$CLJS.eX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Rk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};fX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
gX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
hla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Wk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Wk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
iX=function(a,b){for(;;)switch(b=$CLJS.il.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),hX.h?hX.h(a):hX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(gX,a),b))){var c=a;b=hla(a,b);a=c}else{c=$CLJS.hf.g(hX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};jX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.EA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
aX=function(a){return $CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.IA(fX(a).toLowerCase(),/_/,"-")):a};kX=function(a,b){var c=jX(b);return $CLJS.n(c)?(b=aX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ila=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.mj,aX,$CLJS.WN,aX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=lX.g?lX.g(b,mX):lX.call(null,b,mX);return d.call(c,b,a)})};
jla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=aX(c);var d=ila(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.mj.h(a),$CLJS.JF)&&$CLJS.Va($CLJS.WN.h(a))?$CLJS.R.j(a,$CLJS.WN,$CLJS.IG):a};kla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=fX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(jla(b),$CLJS.T,c)],null)}),a)};
lla=function(a){a=lX.h?lX.h(a):lX.call(null,a);return cX.h?cX.h(a):cX.call(null,a)};nX=function(a){return $CLJS.$W($CLJS.$W($CLJS.fb(function(b,c){return $CLJS.$W(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jD,$CLJS.Fea,$CLJS.JO,mla,$CLJS.eA,$CLJS.ri],null)),$CLJS.jC,lla),$CLJS.YN,$CLJS.BV)};oX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,null],null):a};qX=function(a){return pX.h(oX(a))};
rX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return hX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(pX,b)))};sX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,qX(b)],null),$CLJS.hf.g(pX,c))};tX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,qX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
uX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,qX(a)],null)};vX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,pX.h(a)],null)};
cX=function(a){return wX(function(b){if($CLJS.xd(b))return gla(b);if($CLJS.Va(jX(b)))return b;try{return pX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.OA($CLJS.Hy);if($CLJS.n($CLJS.NA("metabase.mbql.normalize",d))){var e=$CLJS.RE("Invalid clause:");e instanceof Error?$CLJS.MA("metabase.mbql.normalize",d,$CLJS.Fw.l($CLJS.H([b])),e):$CLJS.MA("metabase.mbql.normalize",d,$CLJS.Fw.l($CLJS.H([e,b])),null)}throw $CLJS.gi($CLJS.mE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.yV(c)])),new $CLJS.h(null,
1,[$CLJS.fS,b],null),c);}throw f;}},a)};nla=function(a){return $CLJS.Df($CLJS.cm(cX,xX(a)))};ola=function(a){for(;;)if($CLJS.n(jX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Rk.g(oX,a))};yX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JF);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.JF,pX):a};
pla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,yX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,yX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};zX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.MQ);return $CLJS.n(b)?$CLJS.Sk.j(a,$CLJS.MQ,pla):a};qla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.pQ);b=$CLJS.Va(a)?AX.h?AX.h(b):AX.call(null,b):b;return $CLJS.n(a)?zX(b):b};BX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
rla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.MF);var c=$CLJS.J.g(b,$CLJS.AN),d=$CLJS.J.g(b,$CLJS.XO),e=$CLJS.J.g(b,$CLJS.pQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.XO);var l=$CLJS.bM($CLJS.Hk.g(f,$CLJS.XO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.XO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Sk.j(l,$CLJS.MF,AX):l,t=$CLJS.n(c)?$CLJS.Sk.j(m,$CLJS.AN,$CLJS.Ve($CLJS.Rk,cX)):m,u=$CLJS.n(e)?$CLJS.Sk.j(t,$CLJS.pQ,zX):t;return cX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.gi($CLJS.mE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.yV(m)])),new $CLJS.h(null,1,[$CLJS.MF,a],null),m);throw v;}};
sla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.MF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.dG);c=$CLJS.J.g(c,$CLJS.ZE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.vC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.pG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,m,$CLJS.Hk.g(t,$CLJS.vG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.GV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.CX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.ZE],null),$CLJS.Pk.g($CLJS.Df,$CLJS.Ve($CLJS.Qk,b)))):a};
tla=function(a){try{return sla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.RE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.MF,a],null),b);}throw c;}};
ula=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=DX.g?DX.g(C,K):DX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return DX.g?DX.g(z,C):DX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};vla=function(a,b){a=$CLJS.Rk.g(function(c){var d=$CLJS.be.g(b,EX);return DX.g?DX.g(c,d):DX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
YW=function YW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),YW.g?YW.g(a,k):YW.call(null,a,k)):null},null,null))};
$CLJS.CX=function CX(a){switch(arguments.length){case 3:return CX.j(arguments[0],arguments[1],arguments[2]);case 4:return CX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return CX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return CX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return CX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.CX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.CX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.CX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.CX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.CX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.CX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.CX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.CX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.CX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.CX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.CX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.CX.A=6;
var wX=function wX(a,b){return $CLJS.zV($CLJS.Ve(wX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},hX=function hX(a){for(;;){if($CLJS.xd(a))return $CLJS.Ft(a,hX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ml(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return iX($CLJS.vs,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return iX($CLJS.Cs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return iX($CLJS.Cs,t);case "or":return iX($CLJS.vs,t);default:return $CLJS.Rk.g(hX,a)}}else return a}},wla=new $CLJS.M(null,"value_field","value_field",
-980977878),FX=new $CLJS.M(null,"ascending","ascending",-988350486),GX=new $CLJS.M(null,"named","named",-422393479),HX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),IX=new $CLJS.M(null,"descending","descending",-24766135),JX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),xla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),KX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),LX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),yla=new $CLJS.M(null,"rows","rows",850049680),zla=new $CLJS.M(null,"special-fn","special-fn",1290649344),EX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),mX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Ala=new $CLJS.M(null,"label_field","label_field",-1573182765),Bla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Cla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),mla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var MX,Dla=$CLJS.Xe($CLJS.N),Ela=$CLJS.Xe($CLJS.N),Fla=$CLJS.Xe($CLJS.N),Gla=$CLJS.Xe($CLJS.N),Hla=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));MX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Pk.g(aX,$CLJS.A),Hla,Dla,Ela,Fla,Gla);MX.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,b],null);return null!=a?$CLJS.be.g(b,a):b});
MX.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,a instanceof $CLJS.M?fX(a):a],null)});MX.m(null,$CLJS.IV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(MX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IV,lX.g?lX.g(b,mX):lX.call(null,b,mX),aX(c)],null)});
MX.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=lX.g?lX.g(a,mX):lX.call(null,a,mX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,function(){var d=$CLJS.n($CLJS.wB.h(c))?$CLJS.Sk.j(c,$CLJS.wB,$CLJS.zh):c;d=$CLJS.n($CLJS.vG.h(c))?$CLJS.Sk.j(d,$CLJS.vG,$CLJS.zh):d;return $CLJS.n($CLJS.AR.h(c))?$CLJS.Sk.j(d,$CLJS.AR,function(e){return $CLJS.n($CLJS.KE.h(e))?$CLJS.Sk.j(e,$CLJS.KE,$CLJS.zh):e}):d}()],null)});
MX.m(null,KX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[KX,b instanceof $CLJS.M?fX(b):b,$CLJS.zh.h(a)],null)});MX.m(null,JX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[JX,lX.g?lX.g(b,mX):lX.call(null,b,mX),$CLJS.mt,aX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[JX,lX.g?lX.g(b,mX):lX.call(null,b,mX),aX(c)],null)});
MX.m(null,$CLJS.BG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(MX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.BG,b,c,d],null)),lX.g?lX.g(a,mX):lX.call(null,a,mX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.BG,lX.g?lX.g(b,mX):lX.call(null,b,mX),$CLJS.Fd(c)?c:aX(c),aX(d)],null)});
MX.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,b,aX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,$CLJS.Yv],null)});MX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,b,aX(a)],null)});
MX.m(null,$CLJS.qJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),c,aX(a)],null)});MX.m(null,$CLJS.wJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),c,aX(a)],null)});
MX.m(null,$CLJS.oJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),aX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,lX.g?lX.g(b,mX):lX.call(null,b,mX)],null)});
MX.m(null,$CLJS.uJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.uJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),aX(c),aX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),aX(c)],null)});
MX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fJ,lX.g?lX.g(b,mX):lX.call(null,b,mX),lX.g?lX.g(c,mX):lX.call(null,c,mX),aX(a)],null)});MX.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,b,a],null)});
MX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[aX(a)],null),$CLJS.hf.h(function(c){return lX.g?lX.g(c,mX):lX.call(null,c,mX)}),b)});
var NX=function NX(a){if($CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a))){var c=aX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Zn,null,$CLJS.cs,null,$CLJS.EG,null,$CLJS.yG,null,$CLJS.ot,null,$CLJS.qG,null,$CLJS.JG,null,$CLJS.KG,null,$CLJS.VF,null,$CLJS.aG,null,$CLJS.bs,null,$CLJS.WF,null,$CLJS.DG,null,$CLJS.wG,null,$CLJS.jk,null,$CLJS.Wx,null,$CLJS.jG,null,$CLJS.CG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(jX(a))?(a=$CLJS.A(a),NX.h?NX.h(a):NX.call(null,
a)):null},Ila=new $CLJS.h(null,8,[$CLJS.mj,aX,$CLJS.pQ,function(a){a=bX(a);return $CLJS.y($CLJS.MQ.h(a))?$CLJS.Sk.j(a,$CLJS.MQ,kla):a},$CLJS.MF,new $CLJS.h(null,6,[$CLJS.eG,function OX(a){if($CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(a)))return aX(a);if($CLJS.n(kX(GX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[GX,OX.h?OX.h(a):OX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(jX(a))?NX($CLJS.hd(a)):null)?$CLJS.Rk.g(OX,a):lX.g?lX.g(a,
mX):lX.call(null,a,mX)},$CLJS.eQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[fX(u),lX.g?lX.g(t,mX):lX.call(null,t,mX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[fX(l),lX.g?lX.g(f,mX):lX.call(null,f,mX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.UR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(jX(t))?MX.h(t):$CLJS.ce(MX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(jX(l))?MX.h(l):$CLJS.ce(MX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.OQ,function(a){a=bX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.pQ);if($CLJS.n(b))return a=$CLJS.FV(a,new $CLJS.h(null,1,[$CLJS.pQ,$CLJS.MF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pQ],null),a=lX.g?lX.g(a,b):lX.call(null,a,b),$CLJS.FV(a,new $CLJS.h(null,1,[$CLJS.MF,$CLJS.pQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MF],null);return lX.g?lX.g(a,b):lX.call(null,a,b)},$CLJS.XO,new $CLJS.h(null,1,[EX,nX],null),$CLJS.JN,new $CLJS.h(null,1,[EX,function(a){a=lX.g?lX.g(a,$CLJS.MF):lX.call(null,a,$CLJS.MF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.KE),d=$CLJS.J.g(b,$CLJS.ZE);a=$CLJS.J.g(b,$CLJS.cF);b=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.KE,aX):b;d=$CLJS.n($CLJS.EA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Sk.j(b,$CLJS.ZE,aX):b;return $CLJS.n(a)?$CLJS.Sk.j(d,$CLJS.cF,fX):d}],null)],null),$CLJS.Ly,
new $CLJS.h(null,1,[$CLJS.uO,$CLJS.Td],null),$CLJS.AN,new $CLJS.h(null,1,[EX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.mj),d=$CLJS.J.g(b,$CLJS.BJ),e=$CLJS.J.g(b,$CLJS.Zi);a=$CLJS.J.g(b,HX);b=$CLJS.n(e)?$CLJS.Sk.j(b,$CLJS.Zi,fX):b;c=$CLJS.n(c)?$CLJS.Sk.j(b,$CLJS.mj,aX):b;d=$CLJS.n(d)?$CLJS.Sk.j(c,$CLJS.BJ,function(f){return lX.g?lX.g(f,mX):lX.call(null,f,mX)}):c;d=$CLJS.n(a)?$CLJS.CX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[HX,Ala],null),function(f){return lX.g?lX.g(f,mX):lX.call(null,f,mX)}):d;return $CLJS.n(a)?
$CLJS.CX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[HX,wla],null),function(f){return lX.g?lX.g(f,mX):lX.call(null,f,mX)}):d}],null),$CLJS.vN,function(a){return null==a?null:aX(a)},$CLJS.XO,new $CLJS.h(null,1,[EX,nX],null),LX,aX],null),lX=function lX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
lX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.FA(Ila,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=aX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,lX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=aX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,lX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(jX(a))?MX.h(a):$CLJS.wd(a)?$CLJS.Rk.g(function(e){return lX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),EX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.mE("Error normalizing form: {0}",$CLJS.H([$CLJS.yV(d)])),new $CLJS.h(null,3,[$CLJS.cA,a,$CLJS.pl,c,zla,b],null),d);}throw e;}};lX.A=1;lX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var pX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(jX(f))?$CLJS.A(f):null},e,a,b,c,d)}();pX.m(null,$CLJS.ci,function(a){return a});
pX.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(kX($CLJS.pG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return pX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,c,$CLJS.Fe($CLJS.gl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,$CLJS.Fe(a)],null)});
pX.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,b,a],null)});pX.m(null,$CLJS.cL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(jX(a))?pX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,null],null)});
pX.m(null,KX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,new $CLJS.h(null,1,[$CLJS.wB,a],null)],null)});pX.m(null,Bla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=qX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=qX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,a,$CLJS.R.j(c,$CLJS.KN,b)],null)});
pX.m(null,Cla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=qX(a);return $CLJS.Se.v($CLJS.NV,a,$CLJS.R,$CLJS.H([$CLJS.zP,b]))});
pX.m(null,JX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=qX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.wB);$CLJS.Va(d)||$CLJS.RS.g(d,b)?a=$CLJS.Se.v($CLJS.NV,a,$CLJS.R,$CLJS.H([$CLJS.vG,b])):(c=$CLJS.OA($CLJS.Jy),$CLJS.n($CLJS.NA("metabase.mbql.util",c))&&(b=$CLJS.mE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.MA("metabase.mbql.util",c,$CLJS.Fw(),b):$CLJS.MA("metabase.mbql.util",c,$CLJS.Fw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),pX.h(new $CLJS.P(null,3,5,$CLJS.Q,[JX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
pX.m(null,$CLJS.IV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=qX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,$CLJS.R.j(e,$CLJS.AR,$CLJS.gl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.KE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var PX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.vs,$CLJS.xs],null)),QX=null,RX=0,SX=0;;)if(SX<RX){var TX=QX.X(null,SX);pX.m(null,TX,function(){return function(a){return rX(a)}}(PX,QX,RX,SX,TX));SX+=1}else{var UX=$CLJS.y(PX);if(UX){var VX=UX;if($CLJS.Ad(VX)){var WX=$CLJS.lc(VX),Jla=$CLJS.mc(VX),Kla=WX,Lla=$CLJS.D(WX);PX=Jla;QX=Kla;RX=Lla}else{var XX=$CLJS.A(VX);pX.m(null,XX,function(){return function(a){return rX(a)}}(PX,QX,RX,SX,XX,VX,UX));PX=$CLJS.B(VX);QX=null;RX=0}SX=0}else break}
pX.m(null,$CLJS.YF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,qX(a),qX(b)],null),c)});pX.m(null,$CLJS.BG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=qX(a);a=$CLJS.n(gX($CLJS.pG,a))?$CLJS.NV.l(c,$CLJS.Hk,$CLJS.H([$CLJS.vG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BG,a],null),b)});
for(var YX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.gG,$CLJS.AG,$CLJS.oG,$CLJS.hG,$CLJS.$r,$CLJS.GG,$CLJS.Xr,$CLJS.Zr,$CLJS.Tr,$CLJS.Vr,$CLJS.TF,$CLJS.$F,$CLJS.RF,$CLJS.bG,$CLJS.SF],null)),ZX=null,$X=0,aY=0;;)if(aY<$X){var bY=ZX.X(null,aY);pX.m(null,bY,function(){return function(a){return sX(a)}}(YX,ZX,$X,aY,bY));aY+=1}else{var cY=$CLJS.y(YX);if(cY){var dY=cY;if($CLJS.Ad(dY)){var eY=$CLJS.lc(dY),Mla=$CLJS.mc(dY),Nla=eY,Ola=$CLJS.D(eY);YX=Mla;ZX=Nla;$X=Ola}else{var fY=$CLJS.A(dY);pX.m(null,fY,
function(){return function(a){return sX(a)}}(YX,ZX,$X,aY,fY,dY,cY));YX=$CLJS.B(dY);ZX=null;$X=0}aY=0}else break}pX.m(null,yla,function(){return null});pX.m(null,$CLJS.AM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AM,pX.h(b),a],null)});
pX.m(null,GX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=pX.h;var e=$CLJS.Q;b=pX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,xla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.tE,a],null);return c.call(pX,new $CLJS.P(null,3,5,e,[$CLJS.AM,b,a],null))});
for(var gY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wx,$CLJS.qG],null)),hY=null,iY=0,jY=0;;)if(jY<iY){var kY=hY.X(null,jY);pX.m(null,kY,function(){return function(a){return tX(a)}}(gY,hY,iY,jY,kY));jY+=1}else{var lY=$CLJS.y(gY);if(lY){var mY=lY;if($CLJS.Ad(mY)){var nY=$CLJS.lc(mY),Pla=$CLJS.mc(mY),Qla=nY,Rla=$CLJS.D(nY);gY=Pla;hY=Qla;iY=Rla}else{var oY=$CLJS.A(mY);pX.m(null,oY,function(){return function(a){return tX(a)}}(gY,hY,iY,jY,oY,mY,lY));gY=$CLJS.B(mY);hY=null;iY=0}jY=0}else break}
for(var pY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.jG,$CLJS.LG,$CLJS.VF,$CLJS.EG,$CLJS.CG,$CLJS.Zn,$CLJS.jk,$CLJS.DG,$CLJS.WF],null)),qY=null,rY=0,sY=0;;)if(sY<rY){var tY=qY.X(null,sY);pX.m(null,tY,function(){return function(a){return uX(a)}}(pY,qY,rY,sY,tY));sY+=1}else{var uY=$CLJS.y(pY);if(uY){var vY=uY;if($CLJS.Ad(vY)){var wY=$CLJS.lc(vY),Sla=$CLJS.mc(vY),Tla=wY,Ula=$CLJS.D(wY);pY=Sla;qY=Tla;rY=Ula}else{var xY=$CLJS.A(vY);pX.m(null,xY,function(){return function(a){return uX(a)}}(pY,qY,rY,sY,
xY,vY,uY));pY=$CLJS.B(vY);qY=null;rY=0}sY=0}else break}pX.m(null,$CLJS.aG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,qX(b),a],null)});
for(var yY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wG,$CLJS.yG],null)),zY=null,AY=0,BY=0;;)if(BY<AY){var CY=zY.X(null,BY);pX.m(null,CY,function(){return function(a){return vX(a)}}(yY,zY,AY,BY,CY));BY+=1}else{var DY=$CLJS.y(yY);if(DY){var EY=DY;if($CLJS.Ad(EY)){var FY=$CLJS.lc(EY),Vla=$CLJS.mc(EY),Wla=FY,Xla=$CLJS.D(FY);yY=Vla;zY=Wla;AY=Xla}else{var GY=$CLJS.A(EY);pX.m(null,GY,function(){return function(a){return vX(a)}}(yY,zY,AY,BY,GY,EY,DY));yY=$CLJS.B(EY);zY=null;AY=0}BY=0}else break}
pX.m(null,$CLJS.JG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,pX.h(b),pX.h(a)],null)});
pX.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(pX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,b],null)),lX.l(a,$CLJS.H([mX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[pX.h(v),pX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[pX.h(m),pX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
pX.m(null,$CLJS.IF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,pX.h(a),$CLJS.E.g(0,b)?1:pX.h(b)],null),$CLJS.hf.g(pX,c))});
var xX=function xX(a){return function f(d,e){try{if($CLJS.n(function(){var x=dX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=dX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Wk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.kE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.cs,null,$CLJS.ot,null,$CLJS.KG,null,$CLJS.bs,null,GX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(NX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.lE(xX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Wk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},HY=function HY(a){return function f(d,e){try{var k=dX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.mG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.fG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,FX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,IX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,FX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,IX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.mG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,qX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.fG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,qX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Wk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Wk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.il.h($CLJS.hf.g(HY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.eX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},AX=$CLJS.Pk.g(cX,function(a){var b=BX($CLJS.eG.h(a))?$CLJS.Sk.j(a,$CLJS.eG,nla):a;b=BX($CLJS.dG.h(a))?$CLJS.Sk.j(b,$CLJS.dG,ola):b;b=BX($CLJS.ZE.h(a))?$CLJS.Sk.j(b,$CLJS.ZE,$CLJS.Ve($CLJS.Rk,oX)):b;b=BX($CLJS.UR.h(a))?$CLJS.Sk.j(b,$CLJS.UR,HY):b;return BX($CLJS.OQ.h(a))?
$CLJS.Sk.j(b,$CLJS.OQ,qla):b}),Yla=new $CLJS.h(null,3,[$CLJS.pQ,$CLJS.Td,$CLJS.MF,new $CLJS.h(null,2,[$CLJS.OQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.pQ);if($CLJS.n(b))return a=$CLJS.FV(a,new $CLJS.h(null,1,[$CLJS.pQ,$CLJS.MF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pQ],null),a=DX.g?DX.g(a,b):DX.call(null,a,b),$CLJS.FV(a,new $CLJS.h(null,1,[$CLJS.MF,$CLJS.pQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MF],null);return DX.g?DX.g(a,b):DX.call(null,a,b)},$CLJS.JN,new $CLJS.h(null,1,
[EX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.MF],null);return DX.g?DX.g(a,b):DX.call(null,a,b)}],null)],null),LX,$CLJS.Td],null),DX=function DX(a){switch(arguments.length){case 1:return DX.h(arguments[0]);case 2:return DX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};DX.h=function(a){return DX.g(a,$CLJS.Cf)};
DX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.FA(Yla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?ula(a,b):$CLJS.wd(a)?vla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.cA,a,$CLJS.pl,b],null),d);throw d;}};DX.A=2;
$CLJS.IY=function(){var a=$CLJS.Pk.l(DX,tla,rla,$CLJS.H([lX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.mE("Error normalizing query: {0}",$CLJS.H([$CLJS.yV(c)])),new $CLJS.h(null,1,[$CLJS.MF,b],null),c);}throw d;}}}();$CLJS.JY=function JY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.IY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Dt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=JY.g?JY.g(f,b):JY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};