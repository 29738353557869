var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Ana,Bna,Cna,Dna,Ena,a1;
$CLJS.Y0=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.DA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.EV("Default period","Default periods",e);case "millisecond":return $CLJS.EV("Millisecond","Milliseconds",e);case "second":return $CLJS.EV("Second","Seconds",e);case "minute":return $CLJS.EV("Minute","Minutes",e);case "hour":return $CLJS.EV("Hour","Hours",e);case "day":return $CLJS.EV("Day","Days",e);case "week":return $CLJS.EV("Week","Weeks",
e);case "month":return $CLJS.EV("Month","Months",e);case "quarter":return $CLJS.EV("Quarter","Quarters",e);case "year":return $CLJS.EV("Year","Years",e);case "minute-of-hour":return $CLJS.EV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.EV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.EV("Day of week","Days of week",e);case "day-of-month":return $CLJS.EV("Day of month","Days of month",e);case "day-of-year":return $CLJS.EV("Day of year","Days of year",e);case "week-of-year":return $CLJS.EV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.EV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.EV("Quarter of year","Quarters of year",e);default:return f=$CLJS.Vt($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Gt(" ",$CLJS.ee($CLJS.AA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.Z0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.kC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.iC(f)},e,a,b,c,d)}();Ana=$CLJS.Xe($CLJS.N);Bna=$CLJS.Xe($CLJS.N);
Cna=$CLJS.Xe($CLJS.N);Dna=$CLJS.Xe($CLJS.N);Ena=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.kC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.$0=new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.iC,Ena,Ana,Bna,Cna,Dna);$CLJS.$0.m(null,$CLJS.ci,function(){return null});$CLJS.$0.m(null,$CLJS.kH,function(a){return $CLJS.ri.h(a)});a1=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.bH,null,$CLJS.eH,null,$CLJS.fH,null,$CLJS.cH,null],null),null);
$CLJS.Fna=$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.Qk.h(a1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.cu,$CLJS.kH,$CLJS.ri,a],null);return $CLJS.E.g(a,$CLJS.qk)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.wH);$CLJS.Gna=$CLJS.Rk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.cu,$CLJS.kH,$CLJS.ri,a],null);return $CLJS.E.g(a,$CLJS.du)?$CLJS.R.j(b,$CLJS.ci,!0):b},$CLJS.rH);
$CLJS.Hna=$CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.Qk.h(a1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.cu,$CLJS.kH,$CLJS.ri,a],null);return $CLJS.E.g(a,$CLJS.du)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.xH);$CLJS.F0.m(null,$CLJS.kH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.ri);return $CLJS.Y0.h(a)});
$CLJS.M0.m(null,$CLJS.kH,function(a,b,c){return $CLJS.gl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tE,$CLJS.f0.j(a,b,c),$CLJS.D0,$CLJS.bB($CLJS.$0.h(c))],null),$CLJS.em(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ci,$CLJS.n0],null))]))});
$CLJS.b1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.kC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();$CLJS.b1.m(null,$CLJS.ci,function(){return $CLJS.bh});
$CLJS.c1=function(){function a(d,e,f){return $CLJS.b1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();