var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var WI,YI,$I,lJ,pJ,xJ;$CLJS.VI=new $CLJS.M(null,"get-month","get-month",-369374731);WI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.XI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);YI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.ZI=new $CLJS.M(null,"iso","iso",-1366207543);$I=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.aJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.bJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.cJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.dJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.eJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.fJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.gJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.hJ=new $CLJS.M(null,"us","us",746429226);$CLJS.iJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.jJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.kJ=new $CLJS.M(null,"now","now",-1650525531);lJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.mJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.nJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.oJ=new $CLJS.M(null,"get-week","get-week",-888059349);
pJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.qJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.rJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.sJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.tJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.uJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.vJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.wJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);xJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.yJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.zJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.AJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.BJ=new $CLJS.M(null,"target","target",253001721);$CLJS.EF($CLJS.gJ,$CLJS.H([$CLJS.ot,$CLJS.hD,$CLJS.Hs,$CLJS.jH]));$CLJS.TE.m(null,$CLJS.dJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.UE(a);return $CLJS.vd(a)?(a=$CLJS.lu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.XC,null,$CLJS.wD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.xF($CLJS.dJ,$CLJS.jF);
for(var CJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qJ,$CLJS.wJ],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var GJ=DJ.X(null,FJ);$CLJS.EF(GJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)]));$CLJS.xF(GJ,$CLJS.dJ);FJ+=1}else{var HJ=$CLJS.y(CJ);if(HJ){var IJ=HJ;if($CLJS.Ad(IJ)){var JJ=$CLJS.lc(IJ),Nga=$CLJS.mc(IJ),Oga=JJ,Pga=$CLJS.D(JJ);CJ=Nga;DJ=Oga;EJ=Pga}else{var KJ=$CLJS.A(IJ);$CLJS.EF(KJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.bF],null),$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)]));$CLJS.xF(KJ,$CLJS.dJ);CJ=$CLJS.B(IJ);DJ=null;EJ=0}FJ=0}else break}
for(var LJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bJ,$CLJS.VI,$CLJS.nJ,$CLJS.vJ,$CLJS.rJ,$CLJS.aJ,$CLJS.yJ],null)),MJ=null,NJ=0,OJ=0;;)if(OJ<NJ){var Qga=MJ.X(null,OJ);$CLJS.EF(Qga,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));OJ+=1}else{var PJ=$CLJS.y(LJ);if(PJ){var QJ=PJ;if($CLJS.Ad(QJ)){var RJ=$CLJS.lc(QJ),Rga=$CLJS.mc(QJ),Sga=RJ,Tga=$CLJS.D(RJ);LJ=Rga;MJ=Sga;NJ=Tga}else{var Uga=$CLJS.A(QJ);$CLJS.EF(Uga,$CLJS.H([$CLJS.ot,
$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));LJ=$CLJS.B(QJ);MJ=null;NJ=0}OJ=0}else break}$CLJS.EF($CLJS.fJ,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gH],null)]));
for(var SJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.yJ,null,$CLJS.rJ,null,$CLJS.vJ,null,$CLJS.sJ,null,$CLJS.aJ,null,$CLJS.VI,null,$CLJS.bJ,null,$CLJS.nJ,null],null),null)),TJ=null,UJ=0,VJ=0;;)if(VJ<UJ){var Vga=TJ.X(null,VJ);$CLJS.EF(Vga,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));VJ+=1}else{var WJ=$CLJS.y(SJ);if(WJ){var XJ=WJ;if($CLJS.Ad(XJ)){var YJ=$CLJS.lc(XJ),Wga=$CLJS.mc(XJ),Xga=YJ,Yga=$CLJS.D(YJ);
SJ=Wga;TJ=Xga;UJ=Yga}else{var Zga=$CLJS.A(XJ);$CLJS.EF(Zga,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));SJ=$CLJS.B(XJ);TJ=null;UJ=0}VJ=0}else break}$CLJS.X(xJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.ZI,$CLJS.hJ,$CLJS.mJ],null));
$CLJS.GF($CLJS.oJ,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.tJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.yE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,2,[$CLJS.vt,"valid timezone ID",$CLJS.xt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Dj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.aH($CLJS.Jd,$CLJS.UI.tz.names())),$CLJS.LI],null)],null));
$CLJS.GF($CLJS.XI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tJ],null)],null)],null)],
null)]));$CLJS.xF($CLJS.XI,$CLJS.dJ);$CLJS.EF($CLJS.kJ,$CLJS.H([$CLJS.ot,$CLJS.$C]));$CLJS.X($I,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.wC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XC,$CLJS.wD],null))}],null)],null));
$CLJS.X(WI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wB,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$I],null)],null)],null)],null));
$CLJS.PG.g($CLJS.cJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.vt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.cJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.vt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Yv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dH],null)],null)],null)],null)],null)],null));
$CLJS.TE.m(null,$CLJS.cJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Yv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.wD:$CLJS.n($CLJS.wF($CLJS.iH,a))?$CLJS.XC:$CLJS.wD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.GI,b))?$CLJS.XC:$CLJS.n($CLJS.nh($CLJS.HI,b))?$CLJS.XC:null:null;if($CLJS.n(a))return a;b=$CLJS.UE(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.wC(c,$CLJS.Wj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(pJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.Yv],null),$CLJS.Hs],null));
$CLJS.GF($CLJS.iJ,$CLJS.H([$CLJS.ot,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)],null)],null)],null)]));
$CLJS.EF($CLJS.Ky,$CLJS.H([$CLJS.ot,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hH],null)]));$CLJS.X(YI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ss,$CLJS.fH,$CLJS.zi,$CLJS.mk,$CLJS.eJ,$CLJS.zJ,$CLJS.AJ,$CLJS.Gj,$CLJS.Fi,$CLJS.vi,$CLJS.ij,$CLJS.cH],null));$CLJS.X(lJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,$CLJS.ZI,$CLJS.hJ,$CLJS.mJ],null));
$CLJS.GF($CLJS.uJ,$CLJS.H([$CLJS.ot,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lJ],null)],null)],
null)],null)]));