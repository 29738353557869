var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Wsa,Xsa,Zsa,$sa,ata,bta;Wsa=function(a,b){return $CLJS.KA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Xsa=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};Zsa=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Pk.g($CLJS.lE(function(b){return $CLJS.ZW(b,a)}),$CLJS.hf.h($CLJS.hd)),Ysa)};
$sa=function(a){return new $CLJS.h(null,3,[$CLJS.mj,$CLJS.AB,$CLJS.T,a,$CLJS.Zi,$CLJS.p.h($CLJS.QE())],null)};
ata=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.gl.l($CLJS.H([a,function(){var c=$CLJS.nh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.zW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.mj,$CLJS.BN,$CLJS.iR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.mj,$CLJS.MM,$CLJS.yM,c],null):null}(),$CLJS.n($CLJS.tE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.tE,$CLJS.H1.g($CLJS.G1,b)],null)]))};$CLJS.I8=function(a){a=$CLJS.z1($CLJS.Z_(a));return $CLJS.n($CLJS.FA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,bta],null)))?$CLJS.be.g($CLJS.bh,$CLJS.rO):$CLJS.bh};$CLJS.J8=function(a,b){var c=$CLJS.I8(a);return(0,$CLJS.WW)(a,0,function(d){var e=$CLJS.ku.g(cta,c);d=$CLJS.Se.j($CLJS.Hk,d,e);d=$CLJS.gl.l($CLJS.H([d,$CLJS.em(b,c)]));$CLJS.ku.g(c,$CLJS.fh($CLJS.Xg(b)));$CLJS.LW(a,0);return d})};$CLJS.K8=function(a){return $CLJS.Fe($CLJS.em($CLJS.LW(a,0),$CLJS.I8(a)))};
$CLJS.L8=function(a){return $CLJS.pQ.h($CLJS.LW(a,0))};bta=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.dta=new $CLJS.M(null,"write","write",-1857649168);$CLJS.eta=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var Ysa,cta;Ysa=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.M8=function(){function a(d,e){d=$CLJS.Fe(Zsa(d));var f=$CLJS.Fe($CLJS.fh($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.ku.g(d,f);d=$CLJS.ku.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.A(d);k=$CLJS.A(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.tE.h(f),$CLJS.H1.g($CLJS.G1,d))?$CLJS.H1.g($CLJS.G1,k):$CLJS.tE.h(f);f=$CLJS.R.l($CLJS.Hk.l(f,$CLJS.yM,$CLJS.H([$CLJS.iR,$CLJS.ZP])),$CLJS.tE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Hk.g(e,d),k,f)}else e=$CLJS.gl.l($CLJS.H([Wsa($CLJS.Te(d),
e),Xsa($CLJS.T,$CLJS.hf.g($sa,k))]));e=$CLJS.Ft(e,ata)}else e=$CLJS.N;return e}function b(d){return $CLJS.M8.g?$CLJS.M8.g(d,null):$CLJS.M8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();cta=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.rO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.yE],null)],null));
$CLJS.N8=function(){function a(d,e,f,k){var l=$CLJS.M8.h(e);return $CLJS.J8($CLJS.D1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.cu,$CLJS.hW,$CLJS.tV,f,$CLJS.MQ,l,$CLJS.pQ,e],null)],null)),k)}function b(d,e){return $CLJS.N8.v?$CLJS.N8.v(d,e,null,null):$CLJS.N8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.O8=function(){function a(d,e,f){$CLJS.LW(d,0);return $CLJS.J8($CLJS.D1.g(e,$CLJS.vL.h(d)),f)}function b(d,e){return $CLJS.O8.j?$CLJS.O8.j(d,e,null):$CLJS.O8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.C1.m(null,$CLJS.hW,function(a){return $CLJS.w1($CLJS.I8(a),$CLJS.fh($CLJS.Xg($CLJS.K8(a))))&&!$CLJS.JA($CLJS.L8(a))});