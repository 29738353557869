var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var OV,QV,RV,Nka,VV,WV,XV,Pka,YV,ZV,$V,bW,cW,dW,eW,fW,gW,iW,jW,kW,lW,mW,pW,qW,Oka;OV=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.me(c)}),a)};
$CLJS.PV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Se.g($CLJS.CC,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.ue(t,$CLJS.PE(x,function(){return function(z){return $CLJS.Hk.l(OV(z),$CLJS.wB,$CLJS.H([$CLJS.Ei]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),e($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.PE(u,function(){return function(v){return $CLJS.Hk.l(OV(v),$CLJS.wB,$CLJS.H([$CLJS.Ei]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
QV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cf,a],null)],null);for(var c=$CLJS.Cf;;){var d=$CLJS.rd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,z,C,G,K){return function(S){return $CLJS.eg.j(v,$CLJS.hf.h(function(V,Z,ha,ra,Na){return function(zb){var Pa=$CLJS.I(zb,0,null);zb=$CLJS.I(zb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.be.g(Na,Pa),zb],null)}}(t,u,v,x,z,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.be.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.dm($CLJS.Ps,k)):m}else return c}};RV=function(a,b,c){return $CLJS.zd(c)&&$CLJS.E.g(a,$CLJS.A(c))&&!$CLJS.Hd(b,$CLJS.J.g(c,2))};$CLJS.SV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Pk.g($CLJS.xE,$CLJS.hd)),$CLJS.eQ.h(a));return QV($CLJS.Hk.l(a,$CLJS.JN,$CLJS.H([$CLJS.tV])),function(c){return RV($CLJS.tB,b,c)})};
$CLJS.TV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Pk.g($CLJS.FE,$CLJS.hd)),$CLJS.eG.h(a));return QV($CLJS.Hk.l(a,$CLJS.JN,$CLJS.H([$CLJS.tV])),function(c){return RV($CLJS.eG,b,c)})};Nka=function(a){function b(d){return $CLJS.jf(c,$CLJS.H([$CLJS.JN.h(d)]))}function c(d){return $CLJS.ee($CLJS.cF.h(d),$CLJS.jf(b,$CLJS.H([$CLJS.vL.h(d)])))}return $CLJS.n($CLJS.UV.h(a))?$CLJS.Ue(!0):$CLJS.fh(b(a))};
VV=function(a){if($CLJS.wd(a))for(var b=$CLJS.Ue(!1),c=$CLJS.y(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.A(v),z=$CLJS.B(v),C=x,G=z,K=$CLJS.EA(m,Nka(C));f=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(S,V,Z,ha,ra,Na){return function eb(Pa,Za){try{if($CLJS.zd(Za)&&3===$CLJS.D(Za))try{var Sa=$CLJS.F(Za,0);if($CLJS.he(Sa,$CLJS.pG))try{var mb=$CLJS.F(Za,1);if($CLJS.n($CLJS.zP.h(mb)))try{var Ja=$CLJS.F(Za,1);if(null!=Ja?Ja.C&256||$CLJS.Bc===Ja.mf||(Ja.C?0:$CLJS.$a($CLJS.wb,
Ja)):$CLJS.$a($CLJS.wb,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.zP,$CLJS.JV);if($CLJS.n($CLJS.Te(ha)(bc))){var $d=$CLJS.J.g(Ja,$CLJS.zP);$CLJS.F(Za,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Na),": no join named ",$CLJS.Mh.l($CLJS.H([$d]))].join("")],null)}throw $CLJS.Y;}catch(qd){if(qd instanceof Error){var Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;
}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)return $CLJS.GV(eb,Pa,Za);throw Id;}throw qd;}}}(f,k,l,K,m,t,u,v,x,z,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Cf,$CLJS.Hk.l(C,$CLJS.JN,$CLJS.H([Oka]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};WV=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);XV=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Pka=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);YV=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
ZV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);$V=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.aW=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);bW=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
cW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);dW=new $CLJS.r(null,"refs","refs",80480079,null);eW=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);fW=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);gW=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.hW=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);iW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);jW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);kW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);lW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
mW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.nW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.UV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.oW=new $CLJS.M(null,"filters","filters",974726919);pW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);qW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.rW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Oka=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(eW,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.hW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Dl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.yE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MQ,new $CLJS.h(null,1,[$CLJS.Qs,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lV],null)],null)],null));$CLJS.X(jW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tG],null));
$CLJS.X(WV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),jW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.PV},XV,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.xV,YV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[dW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.PV)?$CLJS.PV.H:null]))],null)],null));
$CLJS.X(kW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.PV},XV,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Ti,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.xV,YV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[dW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.PV)?$CLJS.PV.H:null]))],null)],null));$CLJS.X(lW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.OF],null),$CLJS.ik,$CLJS.Zj],null)],null));
$CLJS.X(iW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),lW],null));
var sW=$CLJS.EA(function(a){a=$CLJS.A($CLJS.SV(a));if($CLJS.n(a)){var b=$CLJS.FA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Mh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.TV(a));if($CLJS.n(a)){var b=$CLJS.FA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(mW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,2,[$CLJS.vt,"Valid references for a single query stage",$CLJS.xt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Dj);return sW(a)}],null),$CLJS.Te(sW)],null));
$CLJS.X($CLJS.rW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.aW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JN,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.dG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),WV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),kW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oW,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),iW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UR,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BL],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UV,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Hd(a,$CLJS.OQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,
new $CLJS.h(null,1,[$CLJS.vt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Te($CLJS.kE($CLJS.kP,$CLJS.UV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mW],null)],null));$CLJS.X(ZV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null)],null)],null)],null));
$CLJS.X($V,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null)],null)],null)],null));$CLJS.X(cW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$V],null)],null));
$CLJS.X(fW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Te($CLJS.EA($CLJS.kP,$CLJS.UV))],null)],null));$CLJS.X(gW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.hW,$CLJS.aW],null));
$CLJS.X(Pka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,gW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.cu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rW],null)],null)],null)],null));
$CLJS.X(qW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,gW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.cu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cW],null)],null)],null)],null));$CLJS.X(bW,fW);
$CLJS.X(pW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,2,[$CLJS.vt,"Valid references for all query stages",$CLJS.xt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Dj);return VV.h?VV.h(a):VV.call(null,a)}],null),$CLJS.Te(VV)],null));
$CLJS.X($CLJS.rL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ql,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bW],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pW],null)],null));
$CLJS.X($CLJS.RL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.nW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$P,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.eL,$CLJS.kL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rL],null)],null)],null),$CLJS.Gka],null));